//colors
$white: white;
$black: #000;
$red: red;
$color_mountain_mist_approx: #999;
$color_dove_gray_approx: #68696d;
$color_pumice_approx: #c7c7c7;
$color_storm_dust_approx: #666;
$alto: #dbdbdb;

#email-unsubscribe {
  @include extend_1;
  h1 {
    font-family: $font_4;
    font-size: 24px;
    padding: 35px 0;
  }
  h2 {
    @include extend_2;
    font-size: 18px;
    font-family: $font_4;
    line-height: 1.5;
    text-transform: none;
  }
  .form-required {
    display: none;
  }
  dd {
    margin: 0;
  }
  .form-item-form-Email-Address > label {
    color: $black;
    display: inline-block;
    font-family: $font_5, $font_6;
  }
  label.option.picker-label {
    font-family: $font_5, $font_6;
    color: $black;
  }
}

.select2-container {
  &.city {
    width: 100%;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    border-width: 1px;
    font-size: 12px;
    text-transform: uppercase;
    border-color: $color_mountain_mist_approx;
    border-style: solid;
    outline: 0 none;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: border-color 0.3s ease-in-out 0s;
  }
  .select2-choice {
    padding: 0 0 0 20px;
    & > .select2-chosen {
      float: left !important;
      color: $color_storm_dust_approx;
      text-decoration: underline;
      margin-right: 170px;
    }
    .select2-arrow b {
      font-size: 30px;
    }
    &.select2-drop-above .select2-choice {
      padding: 0 0 0 20px;
    }
  }
}

@media (min-width: 60.5em) {
  .custom-grid {
    [data-width-sm='4'][data-location-sm='1'][data-height-sm='5'] {
      padding-top: 16.75%;
    }
    [data-width-sm='4'][data-location-sm='1'][data-height-sm='2'] {
      padding-top: 14%;
    }
    .unique-tiny-snowflake {
      padding-top: 14% !important;
    }
  }
  .site-footer--top {
    font-size: 1rem;
    padding: 20px 0 50px;
    .grid-container--6 .grid--item:nth-of-type(6n + 1) {
      width: 16.66667%;
      float: left;
      margin-right: -100%;
      margin-left: 16.5%;
      clear: none;
    }
    .grid-container--6 .grid--item:nth-of-type(6n + 2) {
      width: 16.66667%;
      float: left;
      margin-right: -100%;
      margin-left: 30.2%;
      clear: none;
    }
    .grid-container--6 .grid--item:nth-of-type(6n + 3) {
      width: 16.66667%;
      float: left;
      margin-right: -100%;
      margin-left: 47.2%;
      clear: none;
    }
    .grid-container--6 .grid--item:nth-of-type(6n + 4) {
      width: 26.667%;
      float: left;
      margin-right: -100%;
      margin-left: 62.7%;
      clear: none;
    }
  }
  .site-footer--bottom {
    padding: 0;
    .node-menu {
      padding-left: 16.2%;
    }
    .menu > li.menu-item-container {
      padding-left: 0.2em;
    }
  }
  .store-locator .form--search--advanced .toggle {
    border-bottom: none;
    color: $white;
    display: block;
    text-align: center;
    text-decoration: underline;
    position: absolute;
    right: 25%;
    padding-top: 10px;
  }
  .site-header .site-header__tools .block-template-site-email-signup-coresite-v1 {
    display: block;
  }
}

.text {
  font-family: $font_5, $font_6;
}

.franchise-content-blk {
  height: 236px;
  margin-top: 100px;
  background-color: $black;
  color: $white;
  width: 306px;
  text-align: center;
  padding-top: 15%;
  margin-left: 50px;
}

.franch_name {
  text-transform: uppercase;
  font-size: 19px;
  font-family: $font_4, $font_1, $font_2, $font_3;
  margin-bottom: 8px;
}

.store_link {
  margin-top: 26px;
  a {
    color: $white;
    border: 1px solid $white;
    padding: 12px 23px;
    font-size: 14px;
    font-family: $font_7;
    &:hover {
      text-decoration: underline;
    }
  }
}

[type='text'] {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

[type='email'] {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

[type='tel'] {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

[type='password'] {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

[type='search'] {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

[type='submit'] {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

textarea {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

.form-text {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
}

@media (min-width: 63.9375em) {
  .product--full {
    &.product--shaded {
      .product__header {
        margin-right: 0;
      }
      .product__footer {
        margin-right: 0;
      }
    }
    &.product--shaded--single {
      .product__header {
        margin-right: 0;
      }
      .product__footer {
        margin-right: 0;
      }
    }
  }
  .page--spp__product .product__footer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  header.submenu__header {
    display: block !important;
  }
  body.page-products-bestsellers {
    .grid--mpp {
      padding: 33px 15px 0;
    }
    .field-mobile-template .sec-nav {
      display: none;
    }
  }
  .bestsellers-nav header.submenu__header {
    border-right: none;
    float: none;
    width: auto;
    text-align: center;
    margin-top: 45px;
  }
}

.email-sms-signup-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

.unique-medium-snowflake {
  padding-top: 14.2% !important;
}

#edit-form-email-promotions dd {
  margin-left: 0;
}

#edit-form-agree-policy dd {
  margin-left: 0;
}

.form-item-form-QUESTION-TYPE > label {
  @include extend_4;
}

.site-header .site-header__tools .block-template-site-email-signup-coresite-v1 {
  display: none;
}

.learn_more_btn.btn {
  font-size: 15px;
  width: 100%;
}

.form-item.form-type-checkbox.form-item-form-opt-out-one {
  padding-top: 1px;
}

input.btn.btn--no-border.store-locator__submit2 {
  width: 100%;
}

.custom-grid__item-inner .basic-text-block-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

.franch_desc span {
  font-size: 11px;
}

.resizable-textarea .grippie {
  background: none;
  border: none;
  cursor: auto;
  height: 0;
}

.page--spp__product .product__footer {
  margin-left: 15px;
  margin-right: 15px;
}

.form--search > .form--inline:before {
  content: none;
}

header.submenu__header {
  display: none;
}

body.page-products-bestsellers .sec-nav__item--has-sub-nav .sec-nav__link:before {
  content: '';
}

.custom-grid .custom-grid__item-inner {
  border-bottom: none;
}

.footer-social--mobile .social-links {
  a {
    font-size: 20px;
  }
  li {
    display: inline-block;
    padding-left: 15px;
    &:first-child {
      padding-left: 0;
    }
  }
}

@media (min-width: 60.5em) {
  .sub-options {
    display: block !important;
  }
  .results_side-title {
    display: block !important;
  }
  .tout-block-landscape__headline {
    font-size: 2rem !important;
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
  .store-locator {
    .form--search--advanced {
      .geo_container {
        display: inline-block !important;
        margin: 0 0 20px 0 !important;
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
      }
      .toggle-wrapper {
        margin: 0 0 36px 0 !important;
      }
      .form--search .form--inline .btn {
        bottom: auto !important;
        left: auto !important;
        position: relative !important;
        vertical-align: top !important;
        width: auto !important;
      }
    }
    .store-locator-landing__form .store-locator-landing__title {
      color: $white;
      font-size: 36px !important;
      margin: 20px 0 !important;
      text-align: center;
    }
  }
  .tout-block-landscape {
    border-bottom: none !important;
  }
  input.btn.btn--no-border.store-locator__submit {
    font-size: 15px !important;
  }
}

.store-locator {
  .form--search--advanced {
    .toggle {
      color: $black;
      padding: 0;
    }
    .toggle-wrapper {
      margin: 19px 0 26px 0 !important;
    }
    .form--search {
      margin: 0 0 23px 0;
      .form--inline .btn {
        bottom: 0;
        left: 7%;
        position: absolute;
        width: 66%;
        right: auto;
        border: none !important;
        text-decoration: none !important;
      }
    }
    .geo_container {
      position: absolute;
      left: 74%;
      top: 162px;
    }
  }
  .international-store {
    right: 26%;
  }
  .results_panel {
    .city2 {
      border: 1px solid $color_dove_gray_approx;
      padding-left: 10px;
    }
    .store-locator__submit2 {
      margin-top: 10px;
    }
  }
  .store-locator-landing__form .store-locator-landing__title {
    font-size: 30px;
    margin: 15px 0;
    text-align: center;
  }
}

.tout-block-landscape {
  border-bottom: 1px solid $color_mountain_mist_approx;
}

.tout-block-landscape__headline {
  font-size: 30px;
  text-align: center;
  margin-bottom: -17px;
  margin-top: 50px;
}

.sub-options {
  display: none;
}

.results_side-title {
  display: none;
}

select {
  background: $url_0;
  background-repeat: no-repeat;
  background-position: 90% 58%;
  background-size: 11px;
  border-color: $alto;
  padding: 0 0 0 20px;
}

input.btn.btn--no-border.store-locator__submit {
  font-size: 18px;
}

body.section-stores.device-mobile .site-container {
  min-height: 0;
}

a.btn.tout-block-landscape__cta {
  border: none !important;
  text-decoration: underline;
  font-size: 12px;
  font-family: $font_0, $font_1, $font_2, $font_3;
}
